import React from "react";

import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Zoom from "./pages/Zoom";
import { AlertProvider } from "./context/AlertContext.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Zoom />,
  },
]);

function App() {
  return (
    <AlertProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </AlertProvider>
  );
}

export default App;
