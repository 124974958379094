import React, { useState, useEffect } from "react";
import "./styles.css";

const Alert = ({ message, type, timeout, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [timeout, onClose]);

  return (
    <div className={`alert ${type} ${visible ? "visible" : "hidden"}`}>
      {message}
    </div>
  );
};

export default Alert;
