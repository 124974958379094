import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ZoomMtg } from "@zoom/meetingsdk";
import { useAlert } from "../../context/AlertContext.js";

ZoomMtg.setZoomJSLib("https://source.zoom.us/3.7.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("pt-PT");

function Zoom() {
  const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
  const sdkKey = process.env.REACT_APP_SDK_KEY;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const meetingNumber = queryParams.get("meetingNumber");
  const passWord = queryParams.get("passWord");
  const role = queryParams.get("role");
  const userName = queryParams.get("userName");
  const userEmail = queryParams.get("userEmail");
  const customerKey = queryParams.get("customerKey");

  const addAlert = useAlert();

  const showAlert = (message, type, time) => {
    addAlert(message, type, time);
  };

  useEffect(() => {
    getSignature();
  }, [userName]);

  function getSignature() {
    fetch(authEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function startMeeting(signature) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: window.location.href,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      disableInvite: true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          customerKey,
          success: (success) => {
            ZoomMtg.isSupportVirtualBackground({
              success: data => {
                if (data.result.vb) {
                  ZoomMtg.setVirtualBackground({ id: 'blur' });
                }
              },
            });

            showAlert("Aula Iniciada!", "success", 3000);
          },
          error: (error) => {
            console.error(error)
            showAlert(
              `Erro ao iniciar a aula: ${error.message}`,
              "danger",
              6000
            );
          },
        });
      },
      error: (error) => {
        console.error(error)
        showAlert(`Erro ao iniciar o zoom: ${error.message}`, "danger", 6000);
      },
    });
  }

  return <div></div>;
}

export default Zoom;
